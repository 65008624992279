import { FC } from 'react';
import IconProps from './IconProps';

const HelpIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.152 0.5C2.632 0.5 1.496 1.284 1 2.58L2.008 3.06C2.392 2.18 3.144 1.684 4.104 1.684C5.304 1.684 6.248 2.628 6.248 3.764C6.248 5.108 5.176 6.132 3.416 6.148L3.56 8.308H4.6L4.728 6.948C6.328 6.708 7.496 5.412 7.496 3.78C7.496 1.956 6.152 0.5 4.152 0.5ZM4.088 10.492C3.624 10.492 3.24 10.876 3.24 11.34C3.24 11.82 3.608 12.188 4.088 12.188C4.552 12.188 4.936 11.82 4.936 11.34C4.936 10.876 4.552 10.492 4.088 10.492Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};

export default HelpIcon;
